import Swiper from 'swiper';
import { Navigation, EffectCreative } from 'swiper/modules';
import { pauseAllPlayers } from '../libs/plyr.lib';

const scalpingPopupSlider = document.querySelector('.scalping-videos-slider');
if (scalpingPopupSlider) {
  new Swiper(scalpingPopupSlider, {
    slidesPerView: 1,
    spaceBetween: 0,
    loop: true,
    navigation: {
      nextEl: '.scalping-videos-slider__nav-btn.next',
      prevEl: '.scalping-videos-slider__nav-btn.prev',
    },
    modules: [Navigation],
    on: {
      slideChange: pauseAllPlayers,
    },
  });
}

const reviewsSlider = document.querySelector('.reviews-slider');

if (reviewsSlider) {
  new Swiper(reviewsSlider, {
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 56,
    loop: true,
    loopAdditionalSlides: 1,
    allowTouchMove: false,
    navigation: {
      nextEl: '.reviews-slider__btn.next',
      prevEl: '.reviews-slider__btn.prev',
    },
    modules: [Navigation],
    initialSlide: 1,
    breakpoints: {
      768: {
        spaceBetween: 50,
      },
      1280: {
        spaceBetween: 55,
      },
      1440: {
        spaceBetween: 60,
      },
      1560: {
        spaceBetween: 116,
      },
    },
  });
}

const scalpingEffectivitySlider = document.querySelector(
  '.scalping-effectivity-slider'
);

if (scalpingEffectivitySlider) {
  new Swiper(scalpingEffectivitySlider, {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    navigation: {
      nextEl: '.scalping-effectivity-slider__btn.next',
      prevEl: '.scalping-effectivity-slider__btn.prev',
    },
    modules: [Navigation],
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1280: {
        slidesPerView: 3,
      },
    },
  });
}

const participantsDeals = document.querySelector('.participants-deals-slider');

if (participantsDeals) {
  new Swiper(participantsDeals, {
    slidesPerView: 1,
    spaceBetween: 30,
    loop: true,
    loopAdditionalSlides: 1,
    initialSlide: 2,
    navigation: {
      nextEl: '.participants-deals-slider__nav-btn.next',
      prevEl: '.participants-deals-slider__nav-btn.prev',
    },
    effect: 'creative',
    creativeEffect: {
      prev: {
        shadow: true,
        translate: ['-70%', 0, -800],
      },
      next: {
        shadow: true,
        translate: ['70%', 0, -800],
      },
    },
    modules: [Navigation, EffectCreative],
  });
}
